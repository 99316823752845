<template lang="pug">
.card
  .formSubmitted
    TickOutline.image
    h2 Your form has been submitted. Thank you for your details.
</template>

<script>
import { defineComponent } from 'vue'
import TickOutline from '@/components/vectors/TickOutline.vue'

export default defineComponent({
  components: { TickOutline }
})
</script>

<style scoped lang="scss">
.card {
  margin: 30px 0;

  .formSubmitted {
    margin-top: 130px;
    margin-bottom: 170px;

    .image {
      display: block;
      margin: auto auto 47px;
    }

    h2 {
      font-size: 1.25rem;
      font-weight: 500;
      text-align: center;
    }
  }
}
</style>
